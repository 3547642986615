


import easing from 'jquery.easing';
//import ScrollTrigger from '@terwanerik/scrolltrigger';
import {gsap} from 'gsap';
// import * as PIXI from 'pixi.js';
// import {utils} from 'pixi.js';
// utils.skipHello();

export class Appmv {
  constructor(){
    this.win = window;
    //this.body = document.body;
    //this.page = document.getElementById('page');

    this.winW = window.innerWidth;
    this.winH = window.innerHeight;
    this.ratio = 0.625;
    this.ratio2 = 1.6;
    this.disp_scale = 1.8;


    // window.ua="other";//開発用
    // window.ua="mobile";//開発用



    if(ua === "mobile"){
      this.ratio = 1.6;
      this.ratio2 = 0.625;
      this.disp_scale = .8;
    }
    this.stg = document.getElementById('stg');
    this.target = document.getElementById('target');
    this.circle = document.getElementById('circle');
    this.logo_container = document.getElementById('logo_container');
    // this.btn_skip = document.getElementById('btn_skip');
    this.bottom = document.querySelector("#stg .bottom");

  }
  init(options){
    this.wds = document.querySelectorAll('.wd');
    const ww = 400;//this.winW - 100;
    const wh = 300;//this.winH - 100;
    for(const wd of this.wds){
      //console.log((Math.random()*(this.winW*2)-this.winW)+"px");
      const _ww = (Math.random()*(ww)-ww/2);
      const _wh = (Math.random()*(wh)-wh/2);
      const _ws = Math.random()*1.5;
      //console.log(_ww,_wh);
      wd.style.transform = "translate3d("+_ww+"px,"+_wh+"px,0) scale("+_ws+")";
    }
    this.options = options;

    this.timelines = [];


    this.time_line = gsap.timeline({
      defaults: {
        duration : 1,
        ease:"power2.out"
      }
    });

    // this.time_line4 = gsap.timeline({
    //   defaults: {
    //     ease:"power2.out"
    //   }
    // });
    //
    // this.timelines.push(this.time_line);

    this.time_line_judge = true;


    const images = [];

    this.nextAnim();


  }
  setObj(){

  }
  txtAnim(){
    const lt = 13;

    // this.time_line2 = gsap.timeline({
    //   defaults: {
    //     duration : 2,
    //     ease:"power2.out"
    //   }
    // });
    //
    // this.timelines.push(this.time_line2);


    // this.time_line3 = gsap.timeline({
    //   defaults: {
    //     duration : 2,
    //     ease:"linear"
    //   }
    // });
    //
    // this.timelines.push(this.time_line3);
    //
    //
    //
    // this.time_line3
    // .to('.tt',{
    //   opacity:1,
    //   stagger:3
    // },1.8)
    // .call(()=>{
    //   this.time_line_judge = false;
    //   this.canvas.parentNode.removeChild(this.canvas);
    //   this.nextAnim();
    // });


  }
  // getSize(){
  //   this.clientRect = this.target.getBoundingClientRect();
  //   this.cl_x = this.clientRect.left;
  //   this.cl_y = this.clientRect.top;
  //   this.cl_w = this.clientRect.width;
  //   this.cl_h = this.clientRect.height;
  //   this.cl_px = this.cl_x - this.logo_container.offsetLeft;
  //   this.cl_py = this.cl_y - this.logo_container.offsetTop;
  //   this.cl_rat = 18 / 171;
  //   this.cl_pw = (this.cl_w / 2) * (1 -  this.cl_rat * 2);
  //   this.cir_w = ((this.winW / 3) > 200 ? 200 : (this.winW / 3));
  // }
  nextAnim(){
    //this.getSize();
    //this.btn_skip.style.pointerEvents = 'none';
    //this.btn_skip.classList.add('on');
    const _this = this;

    this.next_tl = gsap.timeline({
      defaults: {
        duration:1,
        ease:"power2.out"
      }
    });
    this.timelines.push(this.next_tl);

    this.next_tl
    .to('.txt1',{
      opacity:1,
      duration:1.6
      //stagger:.7
    },.8)
    // .to('.txt1',{
    //   y:'-100%',
    //   duration:1.2
    // },"m1")
    .to('.txt2',{
      opacity:1,
      duration:1.2
      //stagger:.7
      ,onComplete:function(){
        //console.log('complete');
        setTimeout(()=>{
          for(const wd of _this.wds){
            wd.style.transitionDuration = (Math.random() * 2 + .7)+"s";
            wd.classList.add('on');
          }
        },1000);

      }
    },"m1+=.1")
    //
    .to('.bottommv',{
      opacity:0,
      duration:1,
    },"m1+=1.6")
    .to(this.stg,{
      opacity:0,
      duration:.7,
      ease:"none"
    },"+=3")
    .call(()=>{
      app.open_header();
      //document.body.removeChild();
      // const stg = document.getElementById('stg');
      // console.log(stg.parentNode);
      // stg.parentNode.removeChild(stg);

    });

  }
  events(){
    this.resize();
    window.addEventListener('resize',()=>{
      this.resize();
    });

    // this.btn_skip.addEventListener('click',(e)=>{
    //   e.preventDefault();
    //
    //   this.time_line4
    //   .to(this.stg,{
    //     opacity:0,
    //     ease:"none",
    //     duration:.7
    //   })
    //   .call(()=>{
    //     for(const tl of this.timelines){
    //       tl.pause();
    //     }
    //     app.open_header();
    //   });
    // });


  }
  menu_close(){
  }
  resize(){
    this.winW = window.innerWidth;
    this.winH = window.innerHeight;
    //console.log(this.time_line_judge);
    if(this.time_line_judge){
      //this.app.renderer.resize(this.winW,this.winH);
      //console.log(this.winW * this.ratio, this.winH/this.winW);
      if(this.winH/this.winW > this.ratio){//縦
        this.container.width = this.winH * this.ratio2;
        this.container.height = this.winH;
      }else{
        this.container.width = this.winW;
        this.container.height = this.winW * this.ratio;
      }
    }
    this.getSize();
  }
  scroll(){
  }
}
