
// import "reset-css/reset.css";//開発用
import "../scss/site.scss";
import {Appmv} from './Libmv';
window.appmv = new Appmv();

// 開発用

// appmv.init({
//   autoSpeed:3000,
//   speed:1.5
// });
//
// setTimeout(()=>{
//   //console.log(appmv);
// },1000);
